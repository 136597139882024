import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{class:{ 'pt-5': _vm.app !== 'campus' }},[(!_vm.escuelaTienePermiso)?_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line@5"}}):_c(VCard,[(_vm.app === 'campus')?_c(VTabs,{attrs:{"centered":"","icons-and-text":"","background-color":"secondary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"warning"}}),_c(VTab,{attrs:{"to":"#listado"}},[_vm._v(" Exámenes "),_c(VIcon,[_vm._v("mdi-text-box-check")])],1),_c(VTab,{key:"compartidos",attrs:{"to":"#compartidos"}},[_vm._v(" Compartido "),_c(VIcon,[_vm._v("mdi-account-switch")])],1)],1):_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VSpacer),_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-text-box-check")]),_c('span',[_vm._v("Exámenes")]),_c(VSpacer)],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"value":"listado"}},[_c(VContainer,[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_c(VRow,[_c(VCol,{attrs:{"md":"6","sm":"12"}},[_c(VContainer,[_c('selector-areas',{attrs:{"areas":_vm.areas,"menu":_vm.esAdmin},on:{"change":_vm.getExamenes,"agregarArea":_vm.agregarArea,"modificarArea":_vm.modificarArea},model:{value:(_vm.areaSeleccionada),callback:function ($$v) {_vm.areaSeleccionada=$$v},expression:"areaSeleccionada"}})],1)],1),_c(VCol,{attrs:{"md":"6","sm":"12"}},[_c(VContainer,[_c('div',{staticClass:"d-flex"},[_c(VTextField,{attrs:{"label":"Buscar","append-icon":"mdi-magnify","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.esAdmin)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"margin-left":"10px"},attrs:{"color":"primary","fab":"","small":"","disabled":!_vm.areas.length || !_vm.areaSeleccionada},on:{"click":_vm.abrirCreacionExamen}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-text-box-check")])],1)]}}],null,false,3934007184)},[_c('span',[_vm._v("Agregar examen")])]):_vm._e()],1)])],1)],1)],1),_c(VCardText,[(!_vm.loading && !_vm.examenes.length)?_c('span',[_vm._v("No se han registrado exámenes")]):(_vm.loading)?_c('loading-examenes'):_c('listado-examenes',{attrs:{"examenes":_vm.examenesFiltrados,"areas":_vm.areas},on:{"examenGuardado":_vm.examenGuardado,"examenMovido":_vm.examenMovido,"examenClonado":_vm.examenClonado,"examenEliminado":_vm.examenEliminado}})],1)],1)],1)],1),(_vm.app === 'campus')?_c(VTabItem,{attrs:{"value":"compartidos"}},[_c('examenes-compartidos',{attrs:{"areas":_vm.areas},on:{"examenClonado":_vm.examenClonado}})],1):_vm._e()],1)],1),(_vm.mostrarCreacionArea)?_c('crear-area-dialog',{attrs:{"mostrar":_vm.mostrarCreacionArea,"area":_vm.areaParaModificar},on:{"cancelar":_vm.cerrarCreacionArea,"areaGuardada":_vm.areaCreada}}):_vm._e(),(_vm.mostrarCreacionExamen)?_c('crear-examen-dialog',{attrs:{"mostrar":_vm.mostrarCreacionExamen,"area":_vm.areaSeleccionada,"examen":_vm.examenParaModificar},on:{"cancelar":_vm.cerrarCreacionExamen,"examenGuardado":_vm.examenGuardado}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }