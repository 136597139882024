import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"Buscar","append-icon":"mdi-magnify","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c(VCardText,[(!_vm.loading && !_vm.examenes.length)?_c('span',[_vm._v("Sin exámenes compartidos registrados.")]):(_vm.loading)?_c('loading-examenes'):_c(VList,_vm._l((_vm.examenesFiltrados),function(examen){return _c(VListItem,{key:examen._id},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"primary"}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-text-box-check")])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_c('router-link',{staticClass:"link",attrs:{"to":("examenes/" + (examen._id))}},[_vm._v(_vm._s(examen.nombre)+" ")])],1),_c(VListItemSubtitle,[_c('span',[_vm._v("Creado: "+_vm._s(_vm.getLocaleString(examen.creador.fecha)))]),_c(VChip,{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.getRolExamen(examen)))])],1)],1),_c(VListItemAction,[_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,_vm._l((_vm.menuExamen),function(menuExamenItem,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.menuAction(Object.assign({}, menuExamenItem, {id: examen._id}))}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(menuExamenItem.icon))]),_c('span',[_vm._v(_vm._s(menuExamenItem.text))])],1)],1)}),1)],1)],1)],1)}),1)],1)],1)],1),(_vm.mostrarClonarExamen)?_c('clonar-examen-dialog',{attrs:{"mostrar":_vm.mostrarClonarExamen,"areas":_vm.areas,"examen":_vm.examenParaModificar},on:{"cancelar":_vm.cerrarClonarExamen,"examenClonado":_vm.examenClonado}}):_vm._e(),(_vm.mostrarQuitarExamen)?_c('quitar-examen-dialog',{attrs:{"mostrar":_vm.mostrarQuitarExamen,"examen":_vm.examenParaModificar},on:{"cancelar":_vm.cerrarQuitarExamen,"examenEliminado":_vm.examenEliminado}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }