<template>
  <div>
    <v-container>
      <v-card flat>
        <v-card-title>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <span v-if="!loading && !examenes.length"
            >Sin exámenes compartidos registrados.</span
          >
          <loading-examenes v-else-if="loading"></loading-examenes>
          <v-list v-else>
            <v-list-item v-for="examen in examenesFiltrados" :key="examen._id">
              <v-list-item-avatar>
                <v-avatar color="primary">
                  <v-icon dark>mdi-text-box-check</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link :to="`examenes/${examen._id}`" class="link"
                    >{{ examen.nombre }}
                  </router-link>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span
                    >Creado: {{ getLocaleString(examen.creador.fecha) }}</span
                  >
                  <v-chip x-small class="ml-1">{{
                    getRolExamen(examen)
                  }}</v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(menuExamenItem, index) in menuExamen"
                      :key="index"
                      @click="menuAction({ ...menuExamenItem, id: examen._id })"
                    >
                      <v-list-item-title>
                        <v-icon small class="mr-2">{{
                          menuExamenItem.icon
                        }}</v-icon>
                        <span>{{ menuExamenItem.text }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-container>

    <clonar-examen-dialog
      v-if="mostrarClonarExamen"
      :mostrar="mostrarClonarExamen"
      :areas="areas"
      :examen="examenParaModificar"
      @cancelar="cerrarClonarExamen"
      @examenClonado="examenClonado"
    />

    <quitar-examen-dialog
      v-if="mostrarQuitarExamen"
      :mostrar="mostrarQuitarExamen"
      :examen="examenParaModificar"
      @cancelar="cerrarQuitarExamen"
      @examenEliminado="examenEliminado"
    ></quitar-examen-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import loadingExamenes from "./loadingExamenes.vue";
import { getExamenesCompartidos } from "./examenes.service";

import clonarExamenDialog from "./clonarExamenDialog.vue";

export default {
  props: {
    areas: { type: Array, default: () => [] },
  },

  components: {
    "loading-examenes": loadingExamenes,
    "clonar-examen-dialog": clonarExamenDialog,
    "quitar-examen-dialog": () => import("./quitarExamenCompartidoDialog.vue"),
  },

  data: () => ({
    loading: true,
    search: "",
    examenes: [],
    menuExamen: [
      {
        text: "Copiar a mis recursos",
        action: "clonarExamen",
        icon: "mdi-content-copy",
      },
      {
        text: "Quitar de compartidos",
        action: "quitarExamen",
        icon: "mdi-delete",
      },
    ],
    mostrarClonarExamen: false,
    examenParaModificar: null,
    mostrarQuitarExamen: false,
  }),

  computed: {
    ...mapGetters(["sessionToken", "userId"]),

    examenesFiltrados() {
      if (this.search)
        return this.examenes.filter((e) =>
          e.nombre.toUpperCase().includes(this.search.toUpperCase())
        );
      return this.examenes;
    },
  },

  watch: {
    sessionToken(value) {
      if (value) this.getExamenes();
    },
  },

  mounted() {
    if (this.sessionToken) this.getExamenes();
  },

  methods: {
    getLocaleString(fecha) {
      const date = DateTime.fromISO(fecha)
        .setLocale("es")
        .toLocaleString(DateTime.DATE_FULL);
      return date;
    },

    menuAction(data) {
      switch (data.action) {
        case "clonarExamen":
          this.examenParaModificar = this.examenes.filter(
            (e) => e._id === data.id
          )[0];
          this.mostrarClonarExamen = true;
          break;
        case "quitarExamen":
          this.examenParaModificar = this.examenes.filter(
            (e) => e._id === data.id
          )[0];
          this.mostrarQuitarExamen = true;
          break;
      }
    },

    async getExamenes() {
      this.loading = true;

      try {
        const serverResponse = await getExamenesCompartidos();
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);

        this.examenes = serverResponse.examenes;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    getRolExamen(examen) {
      const esColaborador = examen.colaboradores.find((e) => e == this.userId);

      return esColaborador ? "Colaborador" : "Compartido";
    },

    cerrarClonarExamen() {
      this.mostrarClonarExamen = false;
      this.examenParaModificar = null;
    },

    examenClonado(examen) {
      this.mostrarClonarExamen = false;
      this.examenParaModificar = null;

      this.$emit("examenClonado", examen);
    },

    cerrarQuitarExamen() {
      this.mostrarQuitarExamen = false;
      this.examenParaModificar = null;
    },

    examenEliminado(examen) {
      this.cerrarQuitarExamen();
      this.examenes = this.examenes.filter((e) => e._id != examen._id);
    },
  },
};
</script>
