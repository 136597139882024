<template>
  <v-container :class="{ 'pt-5': app !== 'campus' }">
    <v-skeleton-loader
      v-if="!escuelaTienePermiso"
      type="list-item-two-line@5"
    ></v-skeleton-loader>
    <v-card v-else>
      <v-tabs
        v-if="app === 'campus'"
        v-model="tab"
        centered
        icons-and-text
        background-color="secondary"
        dark
      >
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-tab to="#listado">
          Exámenes
          <v-icon>mdi-text-box-check</v-icon>
        </v-tab>
        <v-tab to="#compartidos" key="compartidos">
          Compartido
          <v-icon>mdi-account-switch</v-icon>
        </v-tab>
      </v-tabs>

      <v-toolbar v-else dense color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer>
        <v-icon class="mr-2">mdi-text-box-check</v-icon>
        <span>Exámenes</span>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item value="listado">
          <v-container>
            <v-card flat>
              <v-card-title>
                <v-row>
                  <v-col md="6" sm="12">
                    <v-container>
                      <selector-areas
                        :areas="areas"
                        :menu="esAdmin"
                        v-model="areaSeleccionada"
                        @change="getExamenes"
                        @agregarArea="agregarArea"
                        @modificarArea="modificarArea"
                      />
                    </v-container>
                  </v-col>
                  <v-col md="6" sm="12">
                    <v-container>
                      <div class="d-flex">
                        <v-text-field
                          v-model="search"
                          label="Buscar"
                          append-icon="mdi-magnify"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                        <v-tooltip v-if="esAdmin" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              color="primary"
                              fab
                              small
                              style="margin-left: 10px"
                              :disabled="!areas.length || !areaSeleccionada"
                              @click="abrirCreacionExamen"
                              ><v-icon>mdi-text-box-check</v-icon></v-btn
                            >
                          </template>
                          <span>Agregar examen</span>
                        </v-tooltip>
                      </div>
                    </v-container>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <span v-if="!loading && !examenes.length"
                  >No se han registrado exámenes</span
                >
                <loading-examenes v-else-if="loading"></loading-examenes>
                <listado-examenes
                  v-else
                  :examenes="examenesFiltrados"
                  :areas="areas"
                  @examenGuardado="examenGuardado"
                  @examenMovido="examenMovido"
                  @examenClonado="examenClonado"
                  @examenEliminado="examenEliminado"
                ></listado-examenes>
              </v-card-text>
            </v-card>
          </v-container>
        </v-tab-item>
        <v-tab-item v-if="app === 'campus'" value="compartidos">
          <examenes-compartidos
            :areas="areas"
            @examenClonado="examenClonado"
          ></examenes-compartidos>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <crear-area-dialog
      v-if="mostrarCreacionArea"
      :mostrar="mostrarCreacionArea"
      :area="areaParaModificar"
      @cancelar="cerrarCreacionArea"
      @areaGuardada="areaCreada"
    />

    <crear-examen-dialog
      v-if="mostrarCreacionExamen"
      :mostrar="mostrarCreacionExamen"
      :area="areaSeleccionada"
      :examen="examenParaModificar"
      @cancelar="cerrarCreacionExamen"
      @examenGuardado="examenGuardado"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getExamenAreas, getExamenesByArea } from "./examenes.service";

import selectorAreas from "../widgets/selectorAreas.vue";
import loadingExamenes from "./loadingExamenes.vue";
import crearAreaExamenesDialog from "./crearAreaExamenesDialog.vue";
import crearExamenDialog from "./crearExamenDialog.vue";
import examenesCompartidos from "./examenesCompartidos.vue";
import { permisoMenuEscuela } from "../../helpers/utilerias";

export default {
  components: {
    "selector-areas": selectorAreas,
    "loading-examenes": loadingExamenes,
    "listado-examenes": () => ({
      component: import("./listadoExamenes.vue"),
      loading: loadingExamenes,
    }),
    "crear-area-dialog": crearAreaExamenesDialog,
    "crear-examen-dialog": crearExamenDialog,
    "examenes-compartidos": examenesCompartidos,
  },

  data: () => ({
    tab: "listado",
    search: "",
    loading: true,
    areas: [],
    areaSeleccionada: "",
    menuArea: [
      { text: "Agregar área", action: "agregarArea", icon: "mdi-plus" },
      { text: "Modificar área", action: "modificarArea", icon: "mdi-pencil" },
    ],
    mostrarCreacionArea: false,
    areaParaModificar: null,
    examenes: [],
    mostrarCreacionExamen: false,
    examenParaModificar: null,
    escuelaTienePermiso: false,
  }),

  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),

    esAdmin() {
      return this.app === "campus" || this.role == "admin" || this.role === "master";
    },

    examenesFiltrados() {
      if (this.search)
        return this.examenes.filter((e) =>
          e.nombre.toUpperCase().includes(this.search.toUpperCase())
        );
      return this.examenes;
    },
  },

  watch: {
    sessionToken() {
      if (!permisoMenuEscuela("Exámenes")) this.$router.push("/dashboard");
      this.escuelaTienePermiso = true;
      this.getExamenAreas();
    },

    areaSeleccionada() {
      this.getExamenes();
    },
  },

  mounted() {
    if (!this.$route.hash && this.app === "campus")
      this.$router.push("#listado");
    if (this.sessionToken) {
      if (!permisoMenuEscuela("Exámenes")) this.$router.push("/dashboard");
      this.getExamenAreas();
      this.escuelaTienePermiso = true;
    }
  },

  methods: {
    menuAction(data) {
      switch (data.action) {
        case "agregarArea":
          this.mostrarCreacionArea = true;
          break;
        case "modificarArea":
          this.areaParaModificar = this.areas.filter(
            (e) => e._id === this.areaSeleccionada
          )[0];
          this.mostrarCreacionArea = true;
          break;
        case "modificarEncuesta":
          this.examenParaModificar = this.encuestas.filter(
            (e) => e._id === data.id
          )[0];
          this.mostrarCreacionExamen = true;
          break;
        case "moverDeArea":
          this.examenParaModificar = this.encuestas.filter(
            (e) => e._id === data.id
          )[0];
          this.mostrarMoverArea = true;
          break;
      }
    },

    agregarArea() {
      this.mostrarCreacionArea = true;
    },

    modificarArea() {
      this.areaParaModificar = this.areas.filter(
        (e) => e._id === this.areaSeleccionada
      )[0];
      this.mostrarCreacionArea = true;
    },
    async getExamenAreas() {
      this.loading = true;

      try {
        const serverResponse = await getExamenAreas();
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.areas = serverResponse.areas;
          if (this.areas.length)
            this.areaSeleccionada = serverResponse.areas[0]._id;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    areaCreada(nuevaArea) {
      const areaExiste = this.areas.filter((e) => e._id === nuevaArea._id)[0];

      if (areaExiste) {
        const query = (element) => element._id === nuevaArea._id;
        const index = this.areas.findIndex(query);
        this.areas.splice(index, 1, nuevaArea);
      } else this.areas = [...this.areas, nuevaArea];

      this.mostrarCreacionArea = false;
      this.areaParaModificar = null;

      this.areaSeleccionada = nuevaArea._id;
    },

    cerrarCreacionArea() {
      this.mostrarCreacionArea = false;
      this.areaParaModificar = null;
    },

    async getExamenes() {
      this.loading = true;

      try {
        const serverResponse = await getExamenesByArea(this.areaSeleccionada);
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.examenes = serverResponse.examenes;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    abrirCreacionExamen() {
      this.mostrarCreacionExamen = true;
    },

    cerrarCreacionExamen() {
      this.mostrarCreacionExamen = false;
      this.examenParaModificar = null;
    },

    examenGuardado(examen) {
      const query = (e) => e._id === examen._id;
      const index = this.examenes.findIndex(query);

      if (index !== -1) this.examenes[index] = examen;
      else this.examenes = [examen, ...this.examenes];

      this.cerrarCreacionExamen();
    },

    examenMovido(examen) {
      this.examenes = this.examenes.filter((e) => e._id !== examen._id);
    },

    examenClonado(examen) {
      const esMismaArea = examen.examenArea === this.areaSeleccionada;
      if (esMismaArea) this.examenes.push(examen);
    },

    examenEliminado(examen) {
      this.examenes = this.examenes.filter((e) => e._id !== examen._id);
    },
  },
};
</script>
